import { getGodEmail } from 'common/lib/godMode';
import type { Team } from '../../types/entities/team';

export const topUsBankingDomains = new Set([
  '1stsource.com',
  '53.com',
  'ally.com',
  'amalgamatedbank.com',
  'amerisbank.com',
  'anb.com',
  'arvest.com',
  'associatedbank.com',
  'bancfirst.com',
  'bancofcal.com',
  'bancorpsouth.com',
  'bankatcity.com',
  'bankatfirst.com',
  'bankerstrust.com',
  'bankofamerica.com',
  'bankofhope.com',
  'bankofthewest.com',
  'bankozarks.com',
  'banksov.com',
  'bankunited.com',
  'bankwithunited.com',
  'bannerbank.com',
  'barclaycardus.com',
  'bbt.com',
  'bbvacompass.com',
  'bellbanks.com',
  'berkshirebank.com',
  'bmoharris.com',
  'bncbanking.com',
  'bnymellon.com',
  'boh.com',
  'bokf.com',
  'bostonprivate.com',
  'bridgenb.com',
  'busey.com',
  'cadencebank.com',
  'capitalbank-us.com',
  'capitalone.com',
  'cardinalbank.com',
  'carterbankandtrust.com',
  'cathaybank.com',
  'cbbank.com',
  'centerstatebank.com',
  'centralpacificbank.com',
  'centurybankandtrust.com',
  'chase.com',
  'chemicalbank.com',
  'cit.com',
  'citigroup.com',
  'citizensbank.com',
  'citynationalcm.com',
  'cnb.com',
  'colonialbank.com',
  'columbiabank.com',
  'comenity.net',
  'comerica.com',
  'commercebank.com',
  'communitybankna.com',
  'connectonebank.com',
  'countrywide.com',
  'ctbi.com',
  'customersbank.com',
  'db.com',
  'discover.com',
  'eaglebankcorp.com',
  'easternbank.com',
  'eastwestbank.com',
  'efirstbank.com',
  'enterprisebank.com',
  'etrade.com',
  'fcbanking.com',
  'fhb.com',
  'firstcitizens.com',
  'firsthorizon.com',
  'firstinterstatebank.com',
  'firstmerchants.com',
  'firstmidwest.com',
  'firstnational.com',
  'firstnbcbank.com',
  'firstrepublic.com',
  'firsttennessee.com',
  'floridacommunitybank.net',
  'flushingbank.com',
  'fmb.com',
  'fnb-online.com',
  'frostbank.com',
  'fsbancorp.com',
  'fult.com',
  'glacierbank.com',
  'goldmansachs.com',
  'greatsouthernbank.com',
  'greenbank.com',
  'hancockwhitney.com',
  'hanmi.com',
  'homebancshares.com',
  'homestreet.com',
  'hsbc.com',
  'huntington.com',
  'ibc.com',
  'iberiabank.com',
  'idbny.com',
  'independentbank.com',
  'intrustbank.com',
  'johnsonbank.com',
  'jpmorganchase.com',
  'key.com',
  'lakecitybank.com',
  'lakelandbank.com',
  'lasallebanks.com',
  'legacytexas.com',
  'leumiusa.com',
  'lionbank.com',
  'lutherburbanksavings.com',
  'mainsourcebank.com',
  'mbfinancial.com',
  'mechanicsbank.com',
  'mercantilcb.com',
  'mibank.com',
  'mizuhobank.com',
  'ml.com',
  'morganstanley.com',
  'mtb.com',
  'mybank.com',
  'nationalbankholdings.com',
  'nbtbank.com',
  'northerntrust.com',
  'northforkbank.com',
  'oldnational.com',
  'opusbank.com',
  'origin.bank',
  'ottobremer.org',
  'pacificwesternbank.com',
  'parknationalbank.com',
  'peoples.com',
  'pinnbank.com',
  'plainscapital.com',
  'pnc.com',
  'pnfp.com',
  'popular.com',
  'popularcommunitybank.com',
  'ppbi.com',
  'prosperitybankusa.com',
  'rabobank.com',
  'raymondjamesbank.com',
  'paypal.com',
  'regions.com',
  'renasantbank.com',
  'republicbank.com',
  'rocklandtrust.com',
  'sabadellunited.com',
  'safra.com',
  'sandyspringbank.com',
  'santanderbank.com',
  'seacoastbank.com',
  'servisfirstbank.com',
  'signatureny.com',
  'simmonsbank.com',
  'southside.com',
  'southstatebank.com',
  'statebt.com',
  'statestreet.com',
  'stbank.com',
  'sterlingbancorp.com',
  'stifel.com',
  'suntrust.com',
  'svb.com',
  'synovus.com',
  'tcbk.com',
  'tcfbank.com',
  'tdbank.com',
  'texascapitalbank.com',
  'thebancorp.com',
  'theprivatebank.com',
  'townebank.com',
  'trustmark.com',
  'ubs.com',
  'ubsh.com',
  'ubsi-inc.com',
  'ucbi.com',
  'umb.com',
  'umpquabank.com',
  'unionbank.com',
  'univest.net',
  'usaa.com',
  'usameribank.com',
  'usbank.com',
  'valleynationalbank.com',
  'wachovia.com',
  'wamu.com',
  'washingtonfederal.com',
  'washtrust.com',
  'watrust.com',
  'websteronline.com',
  'wellsfargo.com',
  'wesbanco.com',
  'westamerica.com',
  'westernalliancebancorporation.com',
  'wfbcds.com',
  'wintrust.com',
  'woodforest.com',
  'yadkinbank.com',
  'zionsbank.com',
]);

//Most pouplar domains on Alexa
export const alexaTop1000Domains = new Set([
  'google.com',
  'youtube.com',
  'facebook.com',
  'baidu.com',
  'wikipedia.org',
  'yahoo.com',
  'google.co.in',
  'qq.com',
  'reddit.com',
  'amazon.com',
  'taobao.com',
  'twitter.com',
  'google.co.jp',
  'tmall.com',
  'live.com',
  'vk.com',
  'instagram.com',
  'sohu.com',
  'sina.com.cn',
  'jd.com',
  'weibo.com',
  '360.cn',
  'google.de',
  'google.co.uk',
  'list.tmall.com',
  'google.com.br',
  'google.fr',
  'google.ru',
  'yandex.ru',
  'google.com.hk',
  'netflix.com',
  'yahoo.co.jp',
  'google.it',
  't.co',
  'ebay.com',
  'google.es',
  'pornhub.com',
  'imgur.com',
  'bing.com',
  'gmw.cn',
  'msn.com',
  'twitch.tv',
  'google.ca',
  'google.com.mx',
  'onclkds.com',
  'tumblr.com',
  'alipay.com',
  'xvideos.com',
  'microsoft.com',
  'livejasmin.com',
  'mail.ru',
  'ok.ru',
  'aliexpress.com',
  'stackoverflow.com',
  'wordpress.com',
  'hao123.com',
  'imdb.com',
  'github.com',
  'amazon.co.jp',
  'blogspot.com',
  'csdn.net',
  'wikia.com',
  'pinterest.com',
  'apple.com',
  'google.com.tr',
  'bongacams.com',
  'popads.net',
  'office.com',
  'youth.cn',
  'paypal.com',
  'microsoftonline.com',
  'google.com.tw',
  'whatsapp.com',
  'google.com.au',
  'google.pl',
  'xhamster.com',
  'detail.tmall.com',
  'diply.com',
  'google.co.id',
  'nicovideo.jp',
  'adobe.com',
  'amazon.de',
  'craigslist.org',
  'txxx.com',
  'dropbox.com',
  'amazon.in',
  'google.com.ar',
  'porn555.com',
  'coccoc.com',
  'booking.com',
  'googleusercontent.com',
  'thepiratebay.org',
  'google.com.pk',
  'china.com',
  'pixnet.net',
  'google.co.th',
  'google.com.eg',
  'tianya.cn',
  'bbc.co.uk',
  'amazon.co.uk',
  'fc2.com',
  'google.com.sa',
  'soso.com',
  'savefrom.net',
  'bbc.com',
  'rakuten.co.jp',
  'uptodown.com',
  'so.com',
  'soundcloud.com',
  'mozilla.org',
  'google.com.ua',
  'amazonaws.com',
  'cnn.com',
  'xnxx.com',
  'quora.com',
  'ask.com',
  'google.nl',
  'ettoday.net',
  'nytimes.com',
  'naver.com',
  'salesforce.com',
  'adf.ly',
  'google.co.za',
  'dailymotion.com',
  'onlinesbi.com',
  'google.co.ve',
  'google.co.kr',
  'steamcommunity.com',
  'espn.com',
  'chase.com',
  'stackexchange.com',
  'blogger.com',
  'fbcdn.net',
  'clicksgear.com',
  'ebay.de',
  'vice.com',
  'vimeo.com',
  'k618.cn',
  'theguardian.com',
  'chaturbate.com',
  'steampowered.com',
  'ebay.co.uk',
  'blastingnews.com',
  'indeed.com',
  'buzzfeed.com',
  'xinhuanet.com',
  'tribunnews.com',
  'avito.ru',
  'mediafire.com',
  'google.gr',
  'openload.co',
  'cnet.com',
  'google.com.sg',
  'globo.com',
  'flipkart.com',
  'dailymail.co.uk',
  'detik.com',
  'spotify.com',
  'zhihu.com',
  'google.com.ph',
  'google.com.vn',
  'cpm10.com',
  'google.se',
  'thestartmagazine.com',
  'google.be',
  'ladbible.com',
  'popcash.net',
  'deviantart.com',
  'google.com.co',
  'roblox.com',
  'github.io',
  'walmart.com',
  'force.com',
  'google.cn',
  'china.com.cn',
  'google.az',
  'uol.com.br',
  'yelp.com',
  'wikihow.com',
  'slideshare.net',
  'w3schools.com',
  'douyu.com',
  'twimg.com',
  'daikynguyenvn.com',
  'godaddy.com',
  'upornia.com',
  'alibaba.com',
  'pinimg.com',
  'washingtonpost.com',
  'sogou.com',
  'hclips.com',
  'nih.gov',
  'etsy.com',
  'babytree.com',
  'softonic.com',
  'google.at',
  'aparat.com',
  'iwanttodeliver.com',
  'google.com.ng',
  'redtube.com',
  'bankofamerica.com',
  'slack.com',
  'youm7.com',
  'pipeschannels.com',
  '9gag.com',
  'mercadolivre.com.br',
  'rambler.ru',
  'myway.com',
  '4chan.org',
  'gfycat.com',
  'amazon.it',
  'amazon.fr',
  'google.com.pe',
  'google.cz',
  'tripadvisor.com',
  'google.ch',
  'google.ro',
  'redd.it',
  'wellsfargo.com',
  'trello.com',
  'metropcs.mobi',
  'discordapp.com',
  'bet365.com',
  '1688.com',
  'indiatimes.com',
  'bp.blogspot.com',
  'zillow.com',
  'google.cl',
  'google.co.ao',
  'google.pt',
  'battle.net',
  'google.no',
  'mama.cn',
  'forbes.com',
  'messenger.com',
  'people.com.cn',
  'kakaku.com',
  'youporn.com',
  'gamepedia.com',
  'ameblo.jp',
  'abs-cbn.com',
  'kinogo.club',
  'yts.ag',
  'google.ae',
  'performanceadexchange.com',
  'ikea.com',
  'tokopedia.com',
  '39.net',
  'outbrain.com',
  'mega.nz',
  'weather.com',
  'thewhizmarketing.com',
  'spotscenered.info',
  'huffingtonpost.com',
  'bilibili.com',
  'codeonclick.com',
  'livejournal.com',
  'speedtest.net',
  'doubleclick.net',
  'kissanime.ru',
  'google.dz',
  'reimageplus.com',
  'google.ie',
  'wetransfer.com',
  'adexchangeprediction.com',
  'huanqiu.com',
  'hdzog.com',
  'foxnews.com',
  'rarbg.to',
  'aol.com',
  'amazon.cn',
  'sharepoint.com',
  'blogspot.co.id',
  'amazon.es',
  'airbnb.com',
  'archive.org',
  'allegro.pl',
  'fmovies.is',
  'google.co.il',
  'bestbuy.com',
  'torrentz2.eu',
  'cnblogs.com',
  'google.hu',
  'bitauto.com',
  'caijing.com.cn',
  'irctc.co.in',
  'ltn.com.tw',
  'leboncoin.fr',
  'businessinsider.com',
  'hotmovs.com',
  'shutterstock.com',
  'google.dk',
  'spankbang.com',
  'feedly.com',
  'freepik.com',
  'chinadaily.com.cn',
  'files.wordpress.com',
  'google.fi',
  'skype.com',
  'wikimedia.org',
  'ebay-kleinanzeigen.de',
  '2ch.net',
  'ign.com',
  'onlinevideoconverter.com',
  'sberbank.ru',
  'kinopoisk.ru',
  'office365.com',
  'breitbart.com',
  'genius.com',
  'blogspot.in',
  'subject.tmall.com',
  'wordreference.com',
  'kompas.com',
  'giphy.com',
  'zippyshare.com',
  'flickr.com',
  'scribd.com',
  'rutracker.org',
  'aliyun.com',
  'eastday.com',
  'xfinity.com',
  'liputan6.com',
  'mailchimp.com',
  'telegram.org',
  'sourceforge.net',
  'researchgate.net',
  'blogspot.com.br',
  'digikala.com',
  'tfetimes.com',
  'gamefaqs.com',
  'accuweather.com',
  'livedoor.com',
  'huaban.com',
  'gismeteo.ru',
  'videodownloadconverter.com',
  'mobileofferplace.site',
  'redirectvoluum.com',
  'homedepot.com',
  'gmx.net',
  'naver.jp',
  'web.de',
  'amazon.ca',
  'youtube-mp3.org',
  'medium.com',
  'rumble.com',
  'google.sk',
  'shopify.com',
  'sabah.com.tr',
  'asos.com',
  'sumatoad.com',
  'mobilemonetize.me',
  'hola.com',
  '1337x.to',
  'orange.fr',
  'ouo.io',
  'oracle.com',
  'dingit.tv',
  'thesaurus.com',
  'hotstar.com',
  'zendesk.com',
  'mediawhirl.net',
  'leagueoflegends.com',
  'daum.net',
  'olx.ua',
  'wordpress.org',
  'media.tumblr.com',
  'icloud.com',
  'iqoption.com',
  'samsung.com',
  'rt.com',
  'capitalone.com',
  'telegraph.co.uk',
  'trackmedia101.com',
  'gearbest.com',
  'goodreads.com',
  'ndtv.com',
  'varzesh3.com',
  'usps.com',
  'hp.com',
  'goo.ne.jp',
  'americanexpress.com',
  'bukalapak.com',
  'ci123.com',
  'atlassian.net',
  'hulu.com',
  'kaskus.co.id',
  'ups.com',
  'userapi.com',
  'taboola.com',
  'target.com',
  'google.kz',
  'beeg.com',
  'subscene.com',
  'rednet.cn',
  'weebly.com',
  'incometaxindiaefiling.gov.in',
  'streamable.com',
  'providr.com',
  'livedoor.jp',
  'wittyfeed.com',
  'mmofreegames.online',
  'onet.pl',
  'canva.com',
  'youdao.com',
  'weevah2.top',
  'independent.co.uk',
  'evernote.com',
  'repubblica.it',
  'jrj.com.cn',
  'rottentomatoes.com',
  'hdfcbank.com',
  'iqiyi.com',
  'bet9ja.com',
  'manoramaonline.com',
  'udemy.com',
  'taleo.net',
  'nametests.com',
  'shink.in',
  'elpais.com',
  'hatenablog.com',
  'spiegel.de',
  'duckduckgo.com',
  'asdfz.pro',
  'cloudfront.net',
  'fiverr.com',
  'intuit.com',
  'mercadolibre.com.ar',
  'go.com',
  'wp.pl',
  'line.me',
  'myanimelist.net',
  'boredpanda.com',
  'hatena.ne.jp',
  'setn.com',
  'google.com.kw',
  'upwork.com',
  'fedex.com',
  'naij.com',
  'reverso.net',
  'themeforest.net',
  'dell.com',
  'patreon.com',
  'yesky.com',
  'espncricinfo.com',
  'oeeee.com',
  'gyazo.com',
  'ensonhaber.com',
  'glassdoor.com',
  'uidai.gov.in',
  'piz7ohhujogi.com',
  'slickdeals.net',
  'sciencedirect.com',
  'qiita.com',
  'nownews.com',
  'scribol.com',
  'behance.net',
  'box.com',
  'ibm.com',
  'groupon.com',
  'patch.com',
  'gizmodo.com',
  'mlb.com',
  'bloomberg.com',
  'seasonvar.ru',
  'cerpen.co.id',
  'haber7.com',
  'zoho.com',
  'wix.com',
  'urbandictionary.com',
  'rediff.com',
  'ytimg.com',
  'marca.com',
  '4dsply.com',
  'doublepimp.com',
  'citi.com',
  'doublepimpssl.com',
  'exoclick.com',
  'google.co.nz',
  'gomovies.to',
  'baike.com',
  'kickstarter.com',
  'yadi.sk',
  'free.fr',
  'coinmarketcap.com',
  '163.com',
  'sportbible.com',
  'ultimate-guitar.com',
  'onedio.com',
  'seznam.cz',
  'yandex.ua',
  'pandora.com',
  'icicibank.com',
  'ebay.it',
  'playstation.com',
  't-online.de',
  'momoshop.com.tw',
  'lazada.co.id',
  'taringa.net',
  'naukri.com',
  'biobiochile.cl',
  'wattpad.com',
  'ebay.com.au',
  'sahibinden.com',
  'siteadvisor.com',
  'theverge.com',
  'wowhead.com',
  'google.bg',
  'pixabay.com',
  'thewhizproducts.com',
  'goal.com',
  'vporn.com',
  'webmd.com',
  'fnkyyrgraizy.com',
  'mi.com',
  'gamer.com.tw',
  'umblr.com',
  'olx.pl',
  'tutorialspoint.com',
  'lifebuzz.com',
  'mercadolibre.com.mx',
  'libero.it',
  'billdesk.com',
  'list-manage.com',
  'reuters.com',
  'conservativetribune.com',
  'albawabhnews.com',
  'tube8.com',
  'shaparak.ir',
  'gsmarena.com',
  'google.lk',
  'merdeka.com',
  'filehippo.com',
  'tvbs.com.tw',
  'xda-developers.com',
  'friv.com',
  'pikabu.ru',
  'usatoday.com',
  'emol.com',
  'chatwork.com',
  'bleacherreport.com',
  'perfecttoolmedia.com',
  'cnzz.com',
  'hilltopads.net',
  'expedia.com',
  'nur.kz',
  'humblebundle.com',
  'pantip.com',
  'att.com',
  'porn.com',
  'hubspot.com',
  'ck101.com',
  'wiktionary.org',
  'asana.com',
  'ptt.cc',
  'uploaded.net',
  'jabong.com',
  'wsj.com',
  'dictionary.com',
  'givemesport.com',
  'flirt4free.com',
  'google.by',
  'paytm.com',
  'liftable.com',
  'blackboard.com',
  'google.rs',
  'nike.com',
  'newegg.com',
  'instructure.com',
  'hurriyet.com.tr',
  'pulseonclick.com',
  '17ok.com',
  'bild.de',
  'likrete.com',
  'azlyrics.com',
  '104.com.tw',
  'wixsite.com',
  'comcast.net',
  'lk21.org',
  'drom.ru',
  'banggood.com',
  'kapanlagi.com',
  'inquirer.net',
  'asus.com',
  'sabq.org',
  'okta.com',
  'google.com.do',
  'tistory.com',
  'chip.de',
  'kijiji.ca',
  'souq.com',
  'mathrubhumi.com',
  'eskimi.com',
  'cricbuzz.com',
  'launchpage.org',
  'newstarads.com',
  'hm.com',
  '51sole.com',
  'npr.org',
  'ouedkniss.com',
  'tabelog.com',
  'poloniex.com',
  'hotels.com',
  'lemonde.fr',
  '4shared.com',
  'engadget.com',
  'dmm.co.jp',
  'mit.edu',
  'elmundo.es',
  'ria.ru',
  'adp.com',
  'apple-panda.com',
  'techcrunch.com',
  'life.tw',
  'wish.com',
  'wiley.com',
  'webex.com',
  'thefreedictionary.com',
  'ctitv.com.tw',
  'sex.com',
  'as.com',
  'youjizz.com',
  'squarespace.com',
  'okdiario.com',
  'weblio.jp',
  'blogspot.mx',
  'hespress.com',
  'getpocket.com',
  'discogs.com',
  'wunderground.com',
  'blog.jp',
  'xe.com',
  'bandcamp.com',
  'youboy.com',
  'cambridge.org',
  'hts68934.com',
  'ntd.tv',
  'jianshu.com',
  'verizonwireless.com',
  'gogoanime.io',
  'jeuxvideo.com',
  'drtuber.com',
  'kooora.com',
  'coinbase.com',
  'vtv.vn',
  'lapatilla.com',
  'springer.com',
  'animeflv.net',
  'blogspot.com.es',
  'blpmovies.com',
  'corriere.it',
  'olx.com.br',
  'thevideo.me',
  'okcupid.com',
  'discuss.com.hk',
  'meetup.com',
  'convert2mp3.net',
  'drive2.ru',
  'linkshrink.net',
  'thekitchn.com',
  'bitbucket.org',
  'lifehacker.com',
  'mercadolibre.com.ve',
  'badoo.com',
  'alicdn.com',
  'surveymonkey.com',
  'mywatchseries.to',
  'google.hr',
  'chinaz.com',
  'milliyet.com.tr',
  'google.com.ly',
  'askcom.me',
  'douban.com',
  'cnbc.com',
  'japanpost.jp',
  'reallifecam.com',
  'quizlet.com',
  'gomovies.is',
  'lenta.ru',
  'ca.gov',
  'indoxxi.net',
  'blogfa.com',
  'lowes.com',
  'neobux.com',
  'superuser.com',
  'voc.com.cn',
  'drudgereport.com',
  'coursera.org',
  'cdiscount.com',
  'goo.gl',
  'crunchyroll.com',
  'southwest.com',
  'www.gov.uk',
  'discover.com',
  'ccm.net',
  'infusionsoft.com',
  'nikkei.com',
  'uptobox.com',
  'terraclicks.com',
  'eksisozluk.com',
  'brilio.net',
  'lenovo.com',
  'realtor.com',
  'liveadexchanger.com',
  'stockstar.com',
  'kinokrad.co',
  'fwbntw.com',
  'rapidgator.net',
  'gamingwonderland.com',
  'banvenez.com',
  'motherless.com',
  'ask.fm',
  'appledaily.com.tw',
  'namnak.com',
  'macys.com',
  'buyma.com',
  'avg.com',
  'moneycontrol.com',
  'investopedia.com',
  'nypost.com',
  'lefigaro.fr',
  'chaoshi.tmall.com',
  'myfreecams.com',
  'ebay.in',
  'mundo.com',
  'subito.it',
  'suning.com',
  'dafont.com',
  'kayak.com',
  'mirror.co.uk',
  'gmarket.co.kr',
  'nordstrom.com',
  'norton.com',
  'bodybuilding.com',
  'urdupoint.com',
  'google.tn',
  'fidelity.com',
  'addthis.com',
  'divar.ir',
  'trackingclick.net',
  'eltrack.pro',
  'sakura.ne.jp',
  'torrent9.cc',
  'secureserver.net',
  'beytoote.com',
  'bitly.com',
  'el-nacional.com',
  'unity3d.com',
  'trulia.com',
  'express.co.uk',
  'eventbrite.com',
  'rbc.ru',
  'fanpage.gr',
  'cisco.com',
  'investing.com',
  'autodesk.com',
  'fanfiction.net',
  'utorrent.com',
  'xtube.com',
  'howtogeek.com',
  'pages.tmall.com',
  'fatosdesconhecidos.com.br',
  'gamespot.com',
  'gotporn.com',
  'thesun.co.uk',
  'nba.com',
  'aniop25.com',
  'newtabtv.com',
  'interia.pl',
  'disqus.com',
  'elmogaz.com',
  'udn.com',
  'android.com',
  'prezi.com',
  'academia.edu',
  'wp.com',
  'vnexpress.net',
  'lolsided.com',
  'tomshardware.com',
  'internetspeedtracker.com',
  'commentcamarche.net',
  'thebalance.com',
  '4pda.ru',
  'caliente.mx',
  'winningdealz.com',
  'php.net',
  'friendsheart.com',
  'google.com.ec',
  'snapdeal.com',
  'grammarly.com',
  'kotaku.com',
  'ecosia.org',
  'oschina.net',
  'cpmofferconvert.com',
  'duolingo.com',
  'bhphotovideo.com',
  'latimes.com',
  'hootsuite.com',
  'viva.co.id',
  'ebc.net.tw',
  'onclickmax.com',
  'pirateproxy.cc',
  'myntra.com',
  'jimdo.com',
  'how01.com',
  'fromdoctopdf.com',
  'intel.com',
  'googlevideo.com',
  'techradar.com',
  'elbalad.news',
  'pchome.com.tw',
  'time.com',
  'ieee.org',
  'g2a.com',
  'voyeurhit.com',
  'timeanddate.com',
  'kizlarsoruyor.com',
  'europa.eu',
  'sockshare.net',
  'tamilrockers.nu',
  'streamcloud.eu',
  'gongchang.com',
  'zone-telechargement.ws',
  'intoday.in',
  'uber.com',
  'zara.com',
  'delta.com',
  'gazeta.pl',
  'rarbg.is',
  'prothom-alo.com',
  'google.iq',
  'lifewire.com',
  'voluumtrk.com',
  'comicbook.com',
  'google.lt',
  'olx.in',
  'jw.org',
  'ebay.fr',
  'wykop.pl',
  'yaplakal.com',
  'vidzi.tv',
  'zoom.us',
  '58.com',
  'indianexpress.com',
  'pof.com',
  'nulltheme.net',
  'fbsbx.com',
  'grid.id',
  'zing.vn',
  'disq.us',
  'westernjournalism.com',
  'y8.com',
  'usnews.com',
  '52pk.com',
  'mobile01.com',
  'khanacademy.org',
  'deviantart.net',
  'protect-your-privacy.net',
  'google.com.mm',
  'okezone.com',
  'kissasian.com',
  'hbogo.com',
  'mobile.de',
  'python.org',
  'instructables.com',
  'cqnews.net',
  'askubuntu.com',
  'mashable.com',
  'prnt.sc',
  'wtoip.com',
  'allrecipes.com',
  'cbsnews.com',
  'stanford.edu',
  'dianping.com',
  'caixa.gov.br',
  'donga.com',
  'kinoprofi.org',
  'panipuri.com',
  'cam4.com',
  'sporx.com',
  'tebyan.net',
  'merriam-webster.com',
  'tnctrx.com',
  'codepen.io',
  'directrev.com',
  'lazada.com.my',
  'sinoptik.ua',
  'sapo.pt',
  'eyny.com',
  'gazetaexpress.com',
  'myfitnesspal.com',
  'state.gov',
  'ukr.net',
  'redfin.com',
  'usaa.com',
  'justdial.com',
  'issuu.com',
  'popmyads.com',
  'google.com.gt',
  'tellonym.de',
  'ticketmaster.com',
  'bittrex.com',
  'thehill.com',
  'mangafox.me',
  'torrentproject.se',
  'primevideo.com',
  'allocine.fr',
  'sh.st',
  'sputniknews.com',
  'idnes.cz',
  'qingdaonews.com',
  'costco.com',
  'bankmellat.ir',
  'food.tmall.com',
  'yenisafak.com',
  'sugklonistiko.gr',
  'primewire.ag',
  'tnaflix.com',
  'huawei.com',
  'dict.cc',
  'labanquepostale.fr',
  'andhrajyothy.com',
  'almasryalyoum.com',
  'solarmoviez.to',
  'nature.com',
  'href.li',
  'ruten.com.tw',
  'elwatannews.com',
  'asahi.com',
  'worldstarhiphop.com',
  'strava.com',
  'zapmeta.ws',
  'agoda.com',
  'mbc.net',
  'gazzetta.it',
  'segmentfault.com',
  'visualstudio.com',
  'ero-advertising.com',
  'focus.de',
  'nocookie.net',
  'airtel.in',
  'google.si',
  'google.tm',
  'nmisr.com',
  'makemytrip.com',
  'united.com',
  'n282adserv.com',
  'metropoles.com',
  'sarkariresult.com',
  'welt.de',
  'zomato.com',
  'op.gg',
  'zhanqi.tv',
  'vetogate.com',
  'chron.com',
  'secure-surf.net',
  'admarketplace.net',
  'bitmedianetwork.com',
  'brazzers.com',
  'cbc.ca',
  'easypdfcombine.com',
  'wayfair.com',
  'bookmyshow.com',
  'td.com',
  'my-hit.org',
  'tomsguide.com',
  'turbobit.net',
  'mixplugin.com',
  'hamariweb.com',
  'nbcnews.com',
  'clipconverter.cc',
  'guancha.cn',
  'ryanair.com',
  'bhaskar.com',
  'ancestry.com',
  'idntimes.com',
  'apache.org',
  'qoolquiz.com',
  'politico.com',
  'java.com',
  'uzone.id',
  'gutefrage.net',
  'gmanetwork.com',
  'lazada.com.ph',
  'yandex.com.tr',
  'dribbble.com',
  'prpops.com',
  'dailypakistan.com.pk',
  'avira.com',
  'ampxchange.com',
  'adnetworkperformance.com',
  'dmv.org',
  'thewhizmarket.co',
  'pastebin.com',
  'custhelp.com',
  'perfectgirls.net',
  'chouftv.ma',
  'superaix.com',
  'world.tmall.com',
  'lun.com',
  'avast.com',
]);

export function prospectOnLinkedInUrl(accountOrOrganizationIds: unknown[]) {
  //entity_ids[]=123&entity_ids[]=456
  return getApiUrl(
    `/mixed_companies/linkedin_prospect_url?${accountOrOrganizationIds
      .map((id: unknown) => `entity_ids[]=${id}`)
      .join('&')}`,
  );
}

export function isValidHostname(url: string) {
  const HOSTNAME_REGEX =
    /^(?:(?:[A-Za-z0-9]+:)?\/\/)?(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,}[A-Za-z0-9])?\.){1,4}[A-Za-z0-9][A-Za-z0-9-]{0,23}[A-Za-z0-9]$/;
  return HOSTNAME_REGEX.test(url);
}

export function isValidUrl(url: string | URL) {
  let validurl: URL;

  try {
    validurl = new URL(url);
  } catch (_) {
    return false;
  }
  return validurl.protocol === 'https:';
}

export function extractHost(url: string) {
  let validurl: URL;
  const urlWithProtocol = addProtocolIfMissing(url);
  try {
    validurl = new URL(urlWithProtocol);
  } catch (_) {
    return false;
  }
  return validurl.hostname;
}

export function extractDomain(url?: string) {
  if (!url || url.length === 0) {
    return null;
  }

  try {
    const hostname = new URL(url).hostname;
    const parts = hostname.split('.');

    if (parts.length < 2) {
      return null;
    }

    return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
  } catch (error) {
    return null;
  }
}

export function isValidDomain(domain: string) {
  // REGEX explanation https://regex101.com/r/HTr6uU/1
  // https://stackoverflow.com/questions/9238640/how-long-can-a-tld-possibly-be (as of now 23)
  const DOMAIN_REGEX =
    /^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,23}[A-Za-z0-9]$/;
  return DOMAIN_REGEX.test(domain);
}

export function getAppBaseUrl() {
  return `https://${process.env.APP_BASE_URL}`;
}

export function getApiAssetsBaseUrl() {
  return 'https://api-assets.apollo.io';
}

export function isStaticDataUrl(url: string) {
  return url.startsWith(getApiAssetsBaseUrl());
}

export function getTailAddressSegment(url: string) {
  const parts = url.split('/').filter((item) => item !== '');
  return parts[parts.length - 1];
}

export function getZpAppUrl(relativeUrl: string | undefined) {
  return `${getAppBaseUrl()}/#${relativeUrl ?? ''}`;
}

export function getApiBaseUrl() {
  return `https://${process.env.APP_BASE_URL}/api/v1`;
}

export function getZpApiUrl(relativeUrl: string | string[]) {
  let baseUrl = `${getApiBaseUrl()}/${relativeUrl}`;
  const godEmail = getGodEmail();
  if (godEmail) {
    if (!relativeUrl.includes('?')) {
      baseUrl = `${baseUrl}?godemail=${godEmail}`;
    } else {
      baseUrl = `${baseUrl}&godemail=${godEmail}`;
    }
  }
  return baseUrl;
}

export function connectToCrmUrl(crm = '', redirect = '', redirectAllCrms = false) {
  if (crm === 'pipedrive') {
    const redirectUrl = redirect ? `&redirect_url=${redirect}` : '';
    return getZpApiUrl(`integration/crm/authorize?crm_name=pipedrive${redirectUrl}`);
  }
  const redirectParam = redirectAllCrms ? `&redirect_url=${redirect}` : '';
  return getZpApiUrl(`${crm}/request_access?surface=app${redirectParam}`);
}

export function unlinkCrmUrl(crm = '', redirectPage = '') {
  if (crm.toLowerCase() === 'pipedrive') {
    return getZpApiUrl('integration/crm/unlink?crm_name=pipedrive');
  }
  if (redirectPage) {
    return getZpApiUrl(`${crm}/unlink_account?redirect_page=${redirectPage}`);
  }
  return getZpApiUrl(`${crm}/unlink_account`);
}

export function getZpWebsite(localPortNumber = '', localProtocol = 'https:') {
  if (process.env.IS_DEVELOPMENT) {
    return `${localProtocol}//localhost:${localPortNumber}`;
  } else if (process.env.IS_STAGING) {
    return 'https://apollo-marketing-site.netlify.app';
  } else {
    return 'https://www.apollo.io';
  }
}

export const publicPath = process.env.IS_DEVELOPMENT && process.env.IS_WEBPACK ? '/public' : '';

// // The file must exist under public/images/*
export function imageUrl(imageName: string, full = false) {
  const prefix = full ? getAppBaseUrl() : '';
  return `${prefix}${publicPath}/images/${imageName}`;
}

export const concatPaths = (...args: (string | undefined | null)[]) => {
  const path = args
    .filter((part): part is string => Boolean(part))
    .map((part) => part.replace(/^\/+|\/+$/g, ''))
    .join('/');

  return `/${path}`;
};

export const readCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.startsWith(' ')) {
      c = c.substring(1, c.length);
    }
    if (c.startsWith(nameEQ)) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

/**
 * Adds a query parameter to a URL. This may not handle hash properly.
 * @param {string} url
 * @param {string} key
 * @param {*} value
 *
 *
 */
export function addQueryParam(url: string, key: string, value: unknown): string {
  const split = url.split('?');
  const query = split.length >= 2 ? split.splice(-1, 1) : [''];

  const searchParams = new URLSearchParams(query[0]);
  searchParams.set(key, value);

  return split.join('?') + '?' + searchParams.toString();
}

/**
 * Removes a query parameter from a URL. This may not handle hash properly.
 *
 */
export function removeQueryParam(url: string, key: string): string {
  return removeQueryParams(url, [key]);
}

/**
 * Removes query parameters from a URL. This may not handle hash properly.
 *
 */
export function removeQueryParams(url: string, keys: string[]): string {
  const split = url.split('?');
  const last = split.splice(-1, 1);

  const searchParams = new URLSearchParams(last[0]);

  keys.forEach((k) => searchParams.delete(k));

  return split.join('?') + '?' + searchParams.toString();
}

export function getRedirectToFromHash() {
  // window.location.search does not work for https://localhost:4001/#/join-team?redirectTo=https%3A%2F%2Flocalhost%3A4001%2F%23%2Fpeople%2F5dc614d7d9ced94b3a524970&_k=vlo3iy
  const redirectTo = new URLSearchParams(window.location.hash.split('?')[1]).get('redirectTo');

  return redirectTo;
}

/**
 * This function updates the current url without causing a browser reload or client side navigation
 * @param {*} path Pathname to be updated
 */
export function updateUrlWithoutRefresh(nextPath: string | URL) {
  window.history.pushState(null, null, nextPath);
}

export function getPathFromUrl(url: string) {
  return url.split('?')[0];
}

export function addProtocolIfMissing(url: string) {
  if (!url || /^https?:\/\//.test(url)) {
    // Don't do anything if http or https is present
    return url;
  }

  if (url.startsWith('//')) {
    return 'https:' + url;
  }

  return 'https://' + url;
}

export function isLocal() {
  const { origin } = window.location;
  return origin.includes('localhost:');
}

export function isStagingOrLocal() {
  const { origin } = window.location;
  return origin.includes('.staging-gcp.apollo.io') || origin.includes('localhost:');
}

export function isConversationsEnabled(currentTeam?: Team): boolean {
  return !!currentTeam?.canAccessConversationIntelligenceModule;
}

export function getApiUrl(path: string | string[]) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  let prefix = '';
  if (process.env.IS_EXTENSION) {
    if (process.env.IS_STAGING || process.env.IS_DEVELOPMENT || process.env.IS_TEST) {
      prefix = `https://${process.env.RAILS_HOST || 'localhost:3001'}/api/v1`;
    } else {
      prefix = process.env.CHROME_EXTENSION_BETA
        ? 'https://beta.apollo.io/api/v1'
        : 'https://extension.apollo.io/api/v1';
    }
  } else {
    prefix = getApiBaseUrl();
  }
  return `${prefix}${adjustedPath}`;
}

/**
 * Replaces entity IDs in the path with `:id`
 * E.g. "contacts/637c8c09573c9d11635dd990" becomes "contacts/:id"
 *
 * @param {string} path
 *
 */
export function replaceIdsWithParameter(path: string): string {
  return path.replace(/\b([0-f]{24})\b/gi, ':id');
}

function isMeetingPath(path: string): path is `/meet/${string}` {
  return path.startsWith('/meet/');
}

function replaceMeetingSlugsWithParameter(path: string): string {
  /**
   * WARNING: EXTREMELY FRAGILE CODE AHEAD.
   *
   * The conditions here are based on the routes declared in the `routes.tsx` file.
   *
   * We need to do this because Datadog SDK does not support React Router v3 and
   * React 17.
   *
   */
  if (!isMeetingPath(path)) {
    return path;
  }

  const rest = path.slice(6);

  // Paths that start with /meet/error do not have parameters.
  if (rest.startsWith('error') || rest.startsWith('inbound-router/error')) {
    return path;
  }

  const segments = rest.split('/');
  if (rest.startsWith('inbound-router')) {
    if (segments[1]) {
      segments[1] = ':schedulingLink';
    }

    return ['/meet', ...segments].join('/');
  }

  if (rest.startsWith('managed-meetings')) {
    if (segments[1]) {
      segments[1] = ':senderMeetingAlias';
    }
    if (segments[2]) {
      segments[2] = ':calendarLink';
    }
    if (segments[3]) {
      segments[3] = ':meetingDuration';
    }

    return ['/meet', ...segments].join('/');
  }

  if (segments[0]) {
    segments[0] = ':calendarLink';
  }

  if (segments[1]) {
    segments[1] = ':meetingDuration';
  }

  return ['/meet', ...segments].join('/');
}

/**
 * Normalizes view names by replacing entity IDs and meeting slugs with parameters
 * @param viewName
 */
export function normalizeViewName(viewName: string): string {
  return replaceMeetingSlugsWithParameter(replaceIdsWithParameter(viewName));
}

/**
 * Returns true if the current page is being loaded in Salesforce iframe mode.
 * The query parameter iframe=sf is set by backend when the page is loaded in Salesforce iframe mode.
 *
 *
 */
export function isSalesforceIframeMode(): boolean {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('iframe') === 'sf';
}

const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_id',
  'utm_term',
  'utm_content',
] as const;

type UtmParamsData = Record<(typeof UTM_KEYS)[number], string> & { referrer: string };

export function utmParamsData(query: Record<string, unknown>): Partial<UtmParamsData> {
  const utmData: Partial<UtmParamsData> = {
    referrer: window.document.referrer,
  };

  UTM_KEYS.forEach((key) => {
    const value = query[key];
    if (value && typeof value === 'string') {
      utmData[key] = value;
    }
  });

  return utmData;
}

export function getAmplitudeProxyUrl() {
  return 'www.apollo.io/amp-outbound/';
}

export function searchParamsToQuery(searchParams: URLSearchParams) {
  return Object.fromEntries(searchParams.entries());
}

// this is similar to searchParamsToQuery but it converts multiple values to an array
export function searchParamsToQueryMultipleValues(searchParams: URLSearchParams) {
  const queryMap: Record<string, string | string[]> = {};
  // Convert URLSearchParams to {}<string, string[]>
  // `key1=value1&key1=value2&key2=value3` => { key1: [value1, value2], key2: value3 }
  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key);
    if (values.length === 1) {
      queryMap[key] = values[0] ?? '';
    } else {
      queryMap[key] = values;
    }
  }

  return queryMap;
}

export function getWebsiteTrackingHost() {
  if (isStagingOrLocal()) {
    return 'https://assets.apollo.io/staging/micro/website-tracker/tracker.iife.js';
  }
  return 'https://assets.apollo.io/micro/website-tracker/tracker.iife.js';
}
